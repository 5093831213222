/* created by @ericariyanto since (c) 2019 */
@font-face {
  font-family: 'Avenir-Black';
  src: url('../fonts/Avenir-Black.ttf') format('ttf');
}
@font-face {
  font-family: 'Avenir-Light';
  src: url('../fonts/Avenir-Light.ttf') format('ttf');
}
@font-face {
  font-family: 'Avenir-Heavy';
  src: url('../fonts/Avenir-Heavy.ttf') format('ttf');
}

@font-face {
    font-family: 'Inter UI Medium';
    font-style: normal;
    font-weight: normal;
    src: local('Inter UI Medium'), url('../fonts/Inter-UI-Medium.woff') format('woff');
}

:root {
    --header-height: 80px;    
    --font-size-largest : 20px;
    --font-size-large : 16px;
    --font-size-normal : 14px;
    --font-size-small : 13px;
    --font-size-smallest : 12px;
    --font-size-smallest-low : 11px;
    --font-size-smallest-super : 10px;
    --font-size-smallest-super-ultra : 9px;
    --color-bg : #1D1E30;
    --color-emphaze : #83b54d;
    --resume-item-height : 55px;
    --resume-item-width : 166px;
    --resume-item-content-width : 92px;
}

div::-webkit-scrollbar-thumb {
    background-color: #30334d;
}

div::-webkit-scrollbar-track {    
    background-color: #1d1f30;
}

body {
    font-family: 'Avenir-Black',Roboto,sans-serif;    
    font-weight: 400;
    line-height: 1.5;
    color: #adb5bd;
    background-color: var(--color-bg);
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

.main-header {
    display: inline-block;    
    width: 100%;
    background-color: #292c42;    
    min-height: var(--header-height);
    max-height: var(--header-height);    
    margin-bottom: -5px;
}
    
    .main-header .logo-app {
        float: left;
        display: inline-block;
        width: 25%;
        max-height: var(--header-height);
        overflow: hidden;
        text-align: left;
        padding: 0px 15px;
        padding-left: 0px;
    }
        .main-header .logo-app .logo-main, 
        .main-header .logo-app .logo-text {
            float: left;
            display: inline-block;            
        }

        .main-header .logo-app .logo-main {
            background-color: #1d1e30;
            padding: 18px 15px;       
        }

        .main-header .logo-app .logo-text {            
            padding: 27px 15px;
        }

        .main-header .logo-app .logo-main img {
            height: 45px;
            width: auto;
            max-width: 100%;
        }

        .main-header .logo-app .logo-text img {
            height: 25px;
            width: auto;
            max-width: 100%;   
        }
    
    .main-header .main-menu-container {
        float: left;
        display: inline-block;
        width: 60%;
        max-height: var(--header-height);
        overflow: hidden;
        text-align: center;        
        padding: 23px 5px;       
    }
        .main-header .main-menu-container .main-search-container {
            display: inline-block;
            width: 100%;
        }

        .main-header .main-menu-container .main-search-container .input-search {
            position: relative;
            width: 80%;
            max-width: 450px;
            margin: 0px auto;
            -webkit-transition: width 1.8s ease-in-out;
            transition: width 1.8s ease-in-out;
        }

        .main-header .main-menu-container .main-search-container .input-search span {
            position: absolute;
            top: 10px;
            left: 20px;
            color: var(--color-emphaze);
        }

        .main-header .main-menu-container .main-search-container .form-control {
            background-color: #30334D;
            border: none;
            border-radius: 100px;
            padding-left: 40px;
            color: rgba(255,255,255,0.47);
            letter-spacing: 0.76px;            
        }

        .main-header .main-menu-container .menu-header-item {
            display: inline-block;
            margin-top: 0px;
        }
            .main-header .main-menu-container .menu-header-item img {
                height: 110px;
                position: relative;
                top: 5px;
                padding: 10px 0px;
            }

    .main-header .timepanel {
        float: right;
        display: inline-block;
        width: 15%;
        max-height: var(--header-height);
        overflow: hidden;
        text-align: center;
        padding: 17px 5px;
    }
    
    .main-header .timepanel .timepanel-datetime {
        display: inline-block;
        width: 100%;
        width: calc(100% - 60px);
        border-right: 1px solid #30334D;
        min-height: 20px;
        text-align: right;
        padding: 0px 0px;
        padding-top: 5px;
        padding-right: 10px;
        color: #FFFFFF;
    }
        .main-header .timepanel .timepanel-datetime .timepanel-datetime-date {
            font-family: 'Avenir-Light',Roboto,sans-serif;
            font-size: var(--font-size-smallest);
            letter-spacing: 0.76px;
        }
        .main-header .timepanel .timepanel-datetime .timepanel-datetime-time {
            font-family: 'Avenir-Heavy',Roboto,sans-serif;
            font-size: var(--font-size-large);
            font-weight: bold;
            letter-spacing: 0.76px;
        }
    .main-header .timepanel .timepanel-weather {
        display: inline-block;
        width: 50px;
    }
        .main-header .timepanel .timepanel-weather img {
            height: 48px;
            margin-top: -20px;
            position: relative;
        }

.main-menu {
    background-color: #1C1C27;
    color: #fff;
    padding: 0px 5%;
    margin-bottom: 10px;
}
    .main-menu ul {            
        display: inline-block;
        width: 100%;
        margin-bottom: 0px;
        padding-left: 0px;
    }
    .main-menu ul li {
        display: inline-block;
    }

    .main-menu ul li a {
        display: inline-block;
        padding: 5px 10px;
        cursor: pointer;
        border-bottom: none;        
        color: #FFFFFF;        
        letter-spacing: 0;
        text-align: left;
        line-height: 21px;
    }
    .main-menu ul li:first-child a {
        margin-left: -10px;        
    }

    .main-menu ul li a:focus, 
    .main-menu ul li a:hover, 
    .main-menu ul li.active a {
        transition-timing-function: ease-in-out;
        color: #84B64E;
        /*border-bottom: 2px solid #84B64E;*/
    }

#page-content-body {
    padding: 0px 5%; 
}

.ps-content-block {
    background: #112D5D;
    color: #FFFFFF;
    padding: 10px 0px;
    padding-bottom: 0px;
    border-radius: 5px;
    margin-bottom: 15px;
}
    .ps-content-block.darker {
        background: #112D5D;
    }
    .ps-content-block.large-widgets {
        background: transparent;
        box-shadow: none;
        border: 1px solid #112D5D;
        font-size: var(--font-size-small);
    }
        .ps-content-block.large-widgets .ps-content-block-body {
            padding: 0px 0px;
        }
        .ps-content-block.large-widgets .ps-content-block-body .large-widget {
            display: inline-block;
            width: 100%;
        }
            .ps-content-block.large-widgets .ps-content-block-body .large-widget .large-widget-title {
                padding: 10px 0px;
                display: inline-block;
                width: 100%;
            }
                .large-widget-title-label,
                .large-widget-title-value {
                    width: 49%;
                    display: inline-block;
                    float: left;
                    text-align: center;
                    padding: 0 15px;
                    font-size: var(--font-size-smallest);
                }
                .large-widget-title-label {
                    padding-top: 4px;
                    line-height: 1.3;
                    position: relative;
                    top: 0px;
                    right: -15%;
                }
                .large-widget-title-value {
                    float: right;
                    text-align: left;
                    font-size: var(--font-size-largest);
                    color: #f8e71d;
                    font-family: Exo-Bold;
                }
            .ps-content-block.large-widgets .ps-content-block-body .large-widget .large-widget-content {
                display: inline-block;
                width: 100%;
                border-top: 1px solid #112D5D;
                padding: 0px 10px;
            }
                .large-widget-content-left, 
                .large-widget-content-center, 
                .large-widget-content-right {
                    display: inline-block;
                    width: 31%;
                    text-align: center;
                    padding: 10px 0px;
                }
                .large-widget-content-center {
                    width: 35%;
                    border-left: 1px solid #112D5D;
                    border-right: 1px solid #112D5D;
                }
                    .ps-content-block.large-widgets .ps-content-block-body .large-widget .large-widget-content .large-widget-content-item {
                        font-size: 25px;
                    }
                        .ps-content-block.large-widgets .ps-content-block-body .large-widget .large-widget-content .large-widget-content-item img {
                            height: 30px;
                            width: auto;
                            margin: 5px 5px;
                            margin-top: 0px;
                            margin-left: 0px;
                        }

    .ps-content-block.with-shadow {
        box-shadow: 0 6px 12px 0 rgba(0,0,0,0.50);
        box-shadow: 0 5px 9px 0px rgba(0, 0, 0, 0.08);
    }
    .ps-content-block .ps-content-block-header {
        display: inline-block;
        width: calc(100% - 50px);
        color: var(--color-emphaze);
        text-transform: uppercase;
        letter-spacing: .18px;
        padding: 0;
        margin: 0 25px;
        margin-bottom: 10px;
    }
        .ps-content-block .ps-content-block-header h3 {
            font-size: var(--font-size-normal);
            margin: 0 0;
            padding: 10px 0;
            padding-top: 0px;
        }
    .ps-content-block .ps-content-block-body {
        padding: 5px 10px;
    }
        .ps-content-block .ps-content-block-body h3.block-title {
            margin: 0px 0px;
            padding: 5px 10px;
            font-size: var(--font-size-small);
        }
        .ps-content-block .ps-content-block-body .ps-content-items {
            height: 295px;
            padding: 5px 5px;
            padding-top: 0px;
            overflow: auto;
            margin-bottom: 10px;
        }        
            .ps-content-block .ps-content-block-body .ps-content-items .ps-content-item {
                display: inline-block;
                width: calc(100% - 5px);
                margin-top: 0;
                margin-bottom: 2%;
                overflow: hidden;
                font-size: var(--font-size-normal);
                color: #adb5bd;
                border: 1px solid #292c42;
                background: #292c42;
                padding: 15px 15px;
                border-radius: 5px;
            }            
                .ps-content-block .ps-content-block-body .ps-content-items .ps-content-item .ps-content-item-cover {
                    display: inline-block;
                    height: 185px;
                    padding: 0px 10px;
                    overflow: hidden;
                }
                    .ps-content-block .ps-content-block-body .ps-content-items .ps-content-item .ps-content-item-cover img {
                        max-width: 100%;
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                    }
                .ps-content-block .ps-content-block-body .ps-content-items .ps-content-item .ps-content-author {
                    display: inline-block;
                    width: 100%;
                    margin-bottom: 5px;
                }
                    .ps-content-block .ps-content-block-body .ps-content-items .ps-content-item .ps-content-author img {
                        width: 40px;
                        height: 40px;
                        float: left;
                    }
                    .ps-content-block .ps-content-block-body .ps-content-items .ps-content-item .ps-content-author .author-content {
                        margin-left: 10px;
                        float: left;
                        padding-top: 2px;
                        width: calc(100% - 50px);
                    }
                .ps-content-block .ps-content-block-body .ps-content-items .ps-content-item .ps-content-item-body {
                    padding: 0px 10px;
                }
                    .ps-content-block .ps-content-block-body .ps-content-items .ps-content-item .ps-content-item-body .ps-content-item-footer {
                        display: inline-block;
                    }
                        .ps-content-block .ps-content-block-body .ps-content-items .ps-content-item .ps-content-item-body .ps-content-item-footer img {
                            display: inline-block;
                            float: left;
                            width: 25%
                        }
                    .ps-content-block .ps-content-block-body .ps-content-items .ps-content-item .ps-content-item-body .ps-content-item-content {
                        overflow: hidden;
                    }
                        .ps-content-block .ps-content-block-body .ps-content-items .ps-content-item .ps-content-item-body .ps-content-item-content p {
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 4;
                            word-wrap: break-word;
                            overflow: hidden;
                        }
                    .ps-content-block .ps-content-block-body .ps-content-items .ps-content-item .ps-content-item-body .ps-content-item-footer-text {
                        display: inline-block;
                        float: left;
                        font-size: var(--font-size-smallest);
                        width: 70%;
                        margin-left: 5%;
                        padding-top: 1px;
                    }
                        .ps-content-block .ps-content-block-body .ps-content-items .ps-content-item .ps-content-item-body .ps-content-item-footer-text .text-name {
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                        .ps-content-block .ps-content-block-body .ps-content-items .ps-content-item .ps-content-item-body .ps-content-item-footer-text .text-time {
                            font-size: var(--font-size-smallest-low);
                        }
            .ps-content-block .ps-content-block-body .ps-content-items .ps-content-item.ps-content-item-full {
                width: 100%;
                height: 98%;
                margin: 0px 0px;
                background: transparent;
                border: none;
            }
                .ps-content-block .ps-content-block-body .ps-content-items .ps-content-item.ps-content-item-full .ps-content-item-cover {
                    height: 98%;
                    border-bottom-left-radius: 5px;
                    border-bottom-right-radius: 5px;
                }            
    .ps-content-block .ps-content-block-footer {
        display: inline-block;
        width: 100%;
        background: #112D5D;
        padding: 13px 10px;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
    }
        .ps-content-block .ps-content-block-footer h3 {
            font-size: var(--font-size-small);
            margin: 0px 0px;
            padding: 0px 0px;
            text-align: center;
        }     

.ps-content-block.ps-content-block-transparant {
    background: #1d1e30;
    padding: 0px 0px;
}   
    .ps-content-block.ps-content-block-transparant .ps-content-block-body {
        padding: 0px 0px;
    }
        .ps-content-block.ps-content-block-transparant .ps-content-block-body .ps-content-items {
            padding: 0px 0px;
        }

.ps-content-block-table {

}
    .ps-content-block-table .ps-content-block-header {
        margin: 0px 15px;
        width: calc(100% - 30px);
    }
    .ps-content-block-table .ps-content-block-body {
        padding: 0px;
        display: inline-block;
        width: calc(100% - 30px);
        margin: 0px 15px;
        height: 90px;
        overflow: hidden;
        overflow-y: auto;        
    }
        .ps-content-block-table .ps-content-block-body .table th {
            font-weight: normal;
        }
            .ps-content-block-table .ps-content-block-body .table th:last-child {
                padding-right: 20px;
            }
        .ps-content-block-table .ps-content-block-body .table-hover>tbody>tr:hover {
            background-color: #204c86;
        }


.content-section {
    display: inline-block;
    width: 100%;
    padding: 5px 0px;
}
    .content-section .section-header {
        display: inline-block;
        width: 100%;
        margin-bottom: 10px;
    }

    .content-section .section-header h3 {
        float: left;
        display: inline-block;
        font-size: var(--font-size-normal);
        color: var(--color-emphaze);
        text-transform: uppercase;
        letter-spacing: 0.96px;
        margin: 0px 0px;
        padding: 5px 0px;
        padding-right: 5px;
    }
    .content-section .section-header .section-header-filter {        
        display: inline-block;
        float: right;
    }
    .content-section .section-header .section-header-filter .filter-options {
        display: inline-block;
    }
    .content-section .section-header .section-header-filter .filter-options .filter-options-item {
        display: inline-block;
    }
    .content-section .section-header .section-header-filter .filter-options .filter-options-item label {
        margin: 0px 0px;
        margin-right: 5px;
        font-size: var(--font-size-smallest-low);
        color: #fff;
    }
    .content-section .section-header .section-header-filter .filter-options .filter-options-item .select2-container {
        display: inline-block;
        min-width: 100px;
        background-color: var(--color-bg);
    }
    .content-section .section-header .section-header-filter .filter-options .filter-options-item .select2-container .select2-selection--single {
        background-color: var(--color-bg);
        border: 1px solid #30334d;
        height: 23px;    
        padding: 3px 24px 3px 12px;
    }
    .content-section .section-header .section-header-filter .filter-options .filter-options-item .select2-container .select2-results__options,
    .content-section .section-header .section-header-filter .filter-options .filter-options-item .select2-container .select2-selection__rendered {
        font-size: var(--font-size-smallest-low) !important;
        color: #fff;
        margin-top: 3px !important;
    }
    .select2-container--bootstrap .select2-results__option,
    .select2-container--bootstrap .select2-results__option[aria-selected=true] {    
        color: #fff;    
        background-color: #1d1e30;
        font-size: var(--font-size-smallest-low);
    }    
    .content-section .section-header .section-header-filter .btn {
        padding: 5px 10px;
        font-size: var(--font-size-smallest-low);
    }

.graph-item {
    overflow: hidden;    
    background-color: #292c42;
    padding: 10px 15px;
    box-shadow: 2px 1px 8px 0 #0000004a;
    font-size: var(--font-size-smallest-low);
}
    .graph-item .graph-item-title {
        display: inline-block;
        width: 100%;
        height: 15px;
        overflow: hidden;
    }

    .graph-item .graph-item-title h5 {
        display: inline-block;
        float: left;
        margin: 0px 0px;
        padding: 2px 2px;   
        font-size: var(--font-size-small);     
    }

    .graph-item .graph-item-title .graph-item-tools {
        display: inline-block;
        float: right;
    }

    .graph-item .graph-item-content {
        height: 30px;
        overflow: hidden;
    }

.sosmed-list {
    display: inline-block;
    float: left;
    width: 100%;
    margin: 0px 0px;
    padding: 0px 0px;    
    max-height: 295px;
    overflow-y: auto;
}

    .sosmed-list .sosmed-item {
        display: inline-block;
        width: 190px;
        margin: 5px 8px;
        margin-left: 0px;
        font-size: var(--font-size-normal);
        color: #adb5bd;
    }

    .sosmed-list .sosmed-item.set-margin-to-left {
        margin: 5px 8px;
        margin-right: 0px;
    }
        .sosmed-list .sosmed-item .sosmed-item-cover {
            width: 100%;
            height: 175px;            
            justify-content: center;
            display: flex;
            flex-direction: row;
            overflow: hidden;
            border-top-right-radius: 5px;
            border-top-left-radius: 5px;            
        }
            .sosmed-list .sosmed-item .sosmed-item-cover img {
                flex: 1;
                height: 100%;
                object-fit: cover;
            }

        .sosmed-list .sosmed-item .sosmed-item-contents {
            display: inline-block;
            float: left;
            width: 100%;
            background-color: #292c42;
            padding: 10px 10px;
            border-bottom-right-radius: 5px;
            border-bottom-left-radius: 5px;
        }
            .sosmed-list .sosmed-item .sosmed-item-contents.no-cover-image {
                border-top-right-radius: 5px;
                border-top-left-radius: 5px;       
            }
            .sosmed-list .sosmed-item .sosmed-item-contents {
                display: inline-block;;
                float: left;
                width: 100%;
                min-height: 50px;
            }

            .sosmed-list .sosmed-item .sosmed-item-contents .sosmed-item-footer {
                display: inline-block;
                float: left;
                width: 100%;
                margin-bottom: 5px;
            }
                .sosmed-list .sosmed-item .sosmed-item-contents .sosmed-item-footer img {
                    display: inline-block;
                    float: left;
                    width: 20px;
                    height: 20px;                    
                }

                .sosmed-list .sosmed-item .sosmed-item-contents .sosmed-item-footer .sosmed-item-footer-author {
                    display: inline-block;
                    float: right;
                    width: 60px;
                }

.sosmed-list-full.sosmed-list {
    max-height: 90vh;
}
.sosmed-list-full.sosmed-list .sosmed-item {
    width: 100%;
    max-width: 890px;
    font-size: var(--font-size-largest);    
}
    .sosmed-list-full.sosmed-list .sosmed-item .sosmed-item-cover {
        height: 430px;
    }
    .sosmed-list-full.sosmed-list .sosmed-item .sosmed-item-contents {
        padding: 20px 25px;
    }
        .sosmed-list-full.sosmed-list .sosmed-item .sosmed-item-contents .sosmed-item-content {
            margin-bottom: 30px;
        }
        .sosmed-list-full.sosmed-list .sosmed-item .sosmed-item-contents .sosmed-item-footer {
            font-size: var(--font-size-small);    
        }
            .sosmed-list-full.sosmed-list .sosmed-item .sosmed-item-contents .sosmed-item-footer img {
                width: 50px;
                height: 50px;
            }
            .sosmed-list-full.sosmed-list .sosmed-item .sosmed-item-contents .sosmed-item-footer .sosmed-item-footer-author {
                width: 75%;
                padding-top: 5px;
                float: left;
                margin-left: 20px;
                font-size: var(--font-size-large);
            }

.psnav-dashboard {
    background: #0c2248;
}
    .psnav-dashboard>.nav-tabs {
        border-bottom-color: #1d1e30;
        background-color: #1d1e30 !important;
        padding: 0px 0px !important;
    }
    .psnav-dashboard>.tab-content,
    .section-content .nav-tabs-custom.psnav-dashboard>.tab-content {
        background: #1d1e30;
        padding: 0px 0px;
    }
    .psnav-dashboard>.nav-tabs>li.active {
        border-top-color: #0c2248;
    }
    .psnav-dashboard>.nav-tabs>li>a {
        color: #fff !important;
        padding: 10px 20px !important;
        font-size: 14px !important;
        background: transparent !important;
    }
    .psnav-dashboard>.nav-tabs>li.active:hover>a, 
    .psnav-dashboard>.nav-tabs>li.active>a {
        background: #212436 !important;
        color: #fff;
        border-right-color: transparent;
        border-left-color: transparent;
    }

.widget-opd {
  background: #212436;
  font-size: 16px;
  color: #4A4A4A;
  text-shadow: 0 6px 12px rgba(0,0,0,0.04);
}

  .widget-opd ul.widget-opd-resume {
    display: flex;
    flex-direction: row;
    margin: 0;
    padding: 0px 0px;
  }
    .widget-opd .widget-opd-resume li {
      list-style: none;
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 0;
      text-align: center;
      border-left: 1px solid #1c1c27;
      border-right: 1px solid #1c1c27;
      border-top: 1px solid #1c1c27;
    }
      .widget-opd .widget-opd-resume li a {
        height: 110px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-weight: 600;
        color: #E0E0E3;
        padding: 0px 15px;
      }
        .widget-opd .widget-opd-resume li a span {
          margin-bottom: 5px;
          font-size: 26px;
          color: #f8e71d;
        }
  .widget-opd .widget-opd-title {
    background: #292c42;
    padding: 10px 15px;
    color: var(--color-emphaze);
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 80px;
  }
    .widget-opd .widget-opd-title h3 {
      text-align: center;
      padding: 0px 0px;
      margin: 0px 0px;
      font-size: 18px;
    }
.widget-opd .widget-opd-detail {
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0px 0px;
}
  .widget-opd .widget-opd-detail .widget-opd-detail-item {
    list-style: none;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
    text-align: center;
  }
    .widget-opd .widget-opd-detail .widget-opd-detail-item h4 {
      background: #0F9F94;
      color: #fff;
      font-size: 24px;
      font-weight: 600;
      padding: 15px 10px;
      margin: 0px 0px;
    }
      .widget-opd .widget-opd-detail .widget-opd-detail-item h4.active {
        background: #0D8077;
      }
    .widget-opd .widget-opd-detail .widget-opd-detail-item .widget-opd-resume li a {
      height: 90px;
      font-size: 13px;
      font-weight: normal;
      border-top: none;
    }
      .widget-opd .widget-opd-detail .widget-opd-detail-item .widget-opd-resume li a span {
        font-size: 18px;
      }

.widget-opd.widget-opd-single .widget-opd-title {
  height: 60px;
}
    .widget-opd.widget-opd-single .widget-opd-detail-item h4 span{
        font-size: 13px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        word-wrap: break-word;
        overflow: hidden;
        margin: 0px 0px;
        padding: 0px 0px;
        background: transparent;
    }
.widget-opd.widget-opd-single .widget-opd-resume li {
  border-top: none;
  font-size: 14px;
}
  .widget-opd.widget-opd-single .widget-opd-resume li a {
    height: 120px;
  }
    .widget-opd.widget-opd-single .widget-opd-resume li a span {
      font-size: 26px;
    }
  .widget-opd.widget-opd-single .widget-opd-detail .widget-opd-detail-item .widget-opd-resume li a {
    font-size: 16px;
    border-bottom: 1px solid #1c1c27;
    height: 100px;
  }
    .widget-opd.widget-opd-single .widget-opd-detail .widget-opd-detail-item .widget-opd-resume li a span {
      font-size: 18px;
    }

.widget-single-item {
  background: #212436;
  font-size: 16px;
  color: #4A4A4A;
  text-shadow: 0 6px 12px rgba(0,0,0,0.04);
}

  .widget-single-item ul.widget-single-item-resume {
    display: flex;
    flex-direction: row;
    margin: 0;
    padding: 0px 0px;
  }
    .widget-single-item .widget-single-item-resume li {
      list-style: none;
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 0;
      text-align: center;
      border-left: 1px solid #1c1c27;
      border-right: 1px solid #1c1c27;
      border-top: 1px solid #1c1c27;
    }
      .widget-single-item .widget-single-item-resume li a {
        height: 110px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-weight: 600;
        color: #E0E0E3;
        padding: 0px 15px;
      }
        .widget-single-item .widget-single-item-resume li a span {
          margin-bottom: 5px;
          font-size: 26px;
          color: #f8e71d;
        }
  .widget-single-item .widget-single-item-title {
    background: #292c42;
    padding: 10px 15px;
    color: var(--color-emphaze);
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 80px;
  }
    .widget-single-item .widget-single-item-title h3 {
      text-align: center;
      padding: 0px 0px;
      margin: 0px 0px;
      font-size: 18px;
    }

.widget-item {
    display: inline-block;
    width: 100%;
    background: #292c42;
    box-shadow: 0 2px 8px 0 rgba(0,0,0,.4);
}
    .widget-item .widget-item-icon {
        display: inline-block;
        width: 35%;
        height: 100%;
    }
        .widget-item .widget-item-icon img {
            max-width: 100%;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    .widget-item .widget-item-content {
        display: inline-block;
        width: calc(100% - 35% - 10px);
        height: 100%;
        overflow: hidden;
        padding: 0 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
        .widget-item .widget-item-content p {


        }
            .widget-item .widget-item-content h4 {
                font-size: 11px;
                font-weight: 600;
                color: #363351;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
                word-wrap: break-word;
                overflow: hidden;
                margin: 0 0;
                margin-bottom: 8px;
            }


.popup-container {
    width: 100%;
    max-width: 1024px;
    background-color: #292c42;
    padding: 30px 30px;
    margin-right: 10px;
}

.infograph {
    display: inline-block;
    width: 100%;    
    min-height: 100px;
    max-height: 600px;
    overflow-y: auto;
    font-size: var(--font-size-normal);  
}
    .infograph .infograph-cover {
        display: inline-block;
        width: 100%;
    }
        .infograph .infograph-cover img {
            width: 100%;
            height: auto;
        }
    .infograph .infograph-content {
        display: inline-block;
        width: 100%;
        padding: 10px 0px;
    }
        .infograph .infograph-content h3 {
            display: inline-block;
            width: 100%;
            margin: 0px;
            margin-bottom: 10px;
            font-size: var(--font-size-large);
        }
        .infograph .infograph-content .infograph-desc {
            font-size: var(--font-size-normal); 
            display: inline-block;
            width: 100%;   
        }
.infograph-list {
    display: inline-block;
    width: 100%;
    max-height: 600px;
    overflow-y: auto;
}
    .infograph-list h4 {
        margin: 0px 0px;
        margin-bottom: 10px;
        padding: 0px 0px;
        color: var(--color-emphaze);
        letter-spacing: 2.76px;
        font-size: var(--font-size-large);
        text-transform: uppercase;
    }
.infograph-list .infograph {
    font-size: var(--font-size-smallest);
    margin-bottom: 10px;
    border-bottom: 1px solid #4e506f;    
}
    .infograph-list .infograph .infograph-cover {
        display: inline-block;
        width: 100%;
        height: 100px;
        overflow: hidden;
    }
        .infograph-list .infograph .infograph-cover img {
            width: 100%;
            height: auto;
            object-fit: fill;
        }
    .infograph-list .infograph .infograph-content {        
        padding: 0px 0px;
    }

.ps-posts {
    display: inline-block;
    width: 100%;
}
    .ps-posts .ps-post {
        display: inline-block;
        width: 145px;        
        overflow: hidden;
        font-size: var(--font-size-smallest);
        color: #adb5bd;
        background-color: #292c42;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        padding: 0px 0px;
        padding-bottom: 15px;
        cursor: pointer;
        margin: 2px 5px;
        margin-bottom: 10px;
        float: left;
    }
    .ps-posts .ps-post:hover {
        color: #adb5bd;
    }
    .ps-posts .ps-post .ps-post-cover {
        display: inline-block;
        width: 100%;
        background-repeat:no-repeat;
        background-position: center center;   
        background-size: cover;         
        height: 95px;
    }

    .ps-posts .ps-post .ps-post-body {
        display: inline-block;
        width: 100%;
        min-height: 30px;
        overflow: hidden;
        padding: 5px 15px;
        padding-top: 0px;
        margin-top: 10px;        
    }
    .ps-posts .ps-post .ps-post-footer {
        display: inline-block;
        width: 100%;
        padding: 5px 15px;
        margin-top: 15px;
    }
        .ps-posts .ps-post .ps-post-footer .ps-post-avatar {
            display: inline-block;
            width: 25px;
            float: left;            
        }
            .ps-posts .ps-post .ps-post-footer .ps-post-avatar img {
                width: 25px;
                height: 25px;
                border-radius: 50%;
            }

        .ps-posts .ps-post .ps-post-footer .ps-post-author {
            display: inline-block;            
            width: 80px;
            float: left;
            margin-left: 10px;
        }

        .ps-posts .ps-post .ps-post-footer .ps-post-author h5 {
            margin: 0px 0px;
            padding: 0px 0px;
            font-size: var(--font-size-smallest);
        }

        .ps-posts .ps-post .ps-post-footer .ps-post-author span {
            font-size: var(--font-size-smallest-low);
        }

table {
    background-color: #292c42;
    font-size: var(--font-size-smallest-low);
    margin-bottom: 5px !important;
}
    table tbody tr,
    .table-striped>tbody>tr:nth-of-type(odd) {
        background-color: #292c42;
    }

    .table-bordered {
        border: none;
    }

    .table-bordered>tbody>tr>td,
    .table-bordered>tbody>tr>th,
    .table-bordered>tfoot>tr>td,
    .table-bordered>tfoot>tr>th,
    .table-bordered>thead>tr>td,
    .table-bordered>thead>tr>th {        
        font-size: var(--font-size-smallest-low);
        border: none;
        border-top: 1px solid #1d1e30;
        padding: 1px 5px;
    }
    .table-bordered>tbody>tr>th {
        border-top: none;
        border-bottom: 1px solid #1d1e30;
    }

.section-content {
    margin-bottom: 10px;
}

.section-content .nav-tabs-custom {
    background-color: #292c42;
    color: #adb5bd;
}
    .section-content .nav-tabs-custom>.nav-tabs,
    .section-content .nav-tabs-custom>.tab-content,
    .section-content .nav-tabs-custom>.nav-tabs>li.active:hover>a, 
    .section-content .nav-tabs-custom>.nav-tabs>li.active>a {
        background-color: #292c42;
        border: none;
    }

    .section-content .nav-tabs-custom>.tab-content {
        padding: 10px 20px;
    }

    .section-content .nav-tabs-custom>.nav-tabs>li.header {
        color: #adb5bd;
        font-size: var(--font-size-small);
    }

    .section-content .nav-tabs-custom>.nav-tabs,
    .section-content .nav-tabs-custom>.nav-tabs>li.active:hover>a,
    .section-content .nav-tabs-custom>.nav-tabs>li.active>a,
    .section-content .nav-tabs-custom>.tab-content {
        padding: 0 10px;
        padding-bottom: 0px;
        overflow: hidden;
    }
    
    .section-content .nav-tabs-custom>.nav-tabs>li>a,
    .section-content .nav-tabs-custom>.nav-tabs>li>a:hover {
        color: #adb5bd;
        font-size: var(--font-size-smallest);
        padding: 7px 5px;
    }

    .section-content .nav-tabs-custom>.nav-tabs>li.header {
        line-height: 26px;
        padding: 0 5px;
    }

.pswidget {
    display: inline-block;
    width: 100px;    
    display: inline-block;
    float: left;
    width: 110px;
    overflow: hidden;
    min-height: var(--resume-item-height);
    padding: 5px 20px;
    background: #292C42;
    box-shadow: 0 2px 8px 0 rgba(0,0,0,0.40);
    margin: 10px 5px;
    margin-top: 0px;
}
.pswidget.pswidget-small {
    width: 60px;
}
    .pswidget .pswidget-content {
        display: inline-block;
        float: left;
        width: 70px;
        padding: 5px 0;
    }
        .pswidget .pswidget-content h4 {
            color: #84b64e;
            margin: 5px 0;
            margin-bottom: 0px;
            margin-top: 7px;
            padding: 0 0;
            font-size: var(--font-size-normal);
        }
        .pswidget .pswidget-content p {
            display: inline-block;
            width: 100%;
            font-size: var(--font-size-smallest-super);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-bottom: 0px;  
        }
    .pswidget .pswidget-icon {
        display: inline-block;
        width: 30px;
        float: right;
        -webkit-transition: all .3s linear;
        -o-transition: all .3s linear;
        transition: all .3s linear;
        color: #546ae0;
        padding: 0px 8px;
        margin: 0px 0px;
        margin-top: 14px;        
    }
        .pswidget .pswidget-icon img {
            max-width: 30px;
            max-height: 30px;
        }

.section-content .resume-item {
    display: inline-block;
    float: left;
    width: var(--resume-item-width);
    overflow: hidden;
    min-height: var(--resume-item-height);
    padding: 5px 20px;
    background: #292C42;
    box-shadow: 0 2px 8px 0 rgba(0,0,0,0.40);
    margin: 10px 5px;
    margin-top: 0px;
}
    .section-content .resume-item.resume-item-last {
        margin-right: 0px;
    }
    .section-content .resume-item .resume-item-content {
        display: inline-block;
        float: left;
        width: var(--resume-item-content-width);
        padding: 5px 0;
    }

    .section-content .resume-item .resume-item-content h4 {
        color: #84b64e;
        margin: 5px 0;
        margin-bottom: 0px;
        margin-top: 7px;
        padding: 0 0;
        font-size: var(--font-size-normal);
    }

    .section-content .resume-item .resume-item-content p {
        display: inline-block;
        width: 100%;
        font-size: var(--font-size-smallest-super);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 0px;    
    }

    .section-content .resume-item .resume-item-icon {        
        display: inline-block;
        width: 30px;
        float: right;
        -webkit-transition: all .3s linear;
        -o-transition: all .3s linear;
        transition: all .3s linear;
        color: #546ae0;
        padding: 0px 8px;
        margin: 0px 0px;
        margin-top: 14px;
    }

    .section-content .resume-item .resume-item-icon i {
        font-size: 24px;
    }

.resume-widget .resume-item {
    width: 110px;
    padding: 5px 10px;
    height: 60px;
}
    .resume-widget .resume-item .resume-item-content {
        width: 58px;
    }
        .resume-widget .resume-item .resume-item-content h4{
            font-size: var(--font-size-smallest);
        }
        .resume-widget .resume-item .resume-item-content p {
            white-space: pre-wrap;
            max-height: 23px;
            padding-top: 5px;
            font-size: var(--font-size-smallest-super-ultra);
        }
    .resume-widget .resume-item .resume-item-icon {

    }
        .resume-widget .resume-item .resume-item-icon img {
            width: 25px;
            padding-top: 3px;
        }
        .resume-widget .resume-item .resume-item-icon i {
            font-size: 18px;
        }

.resume-widget .resume-item.resume-item-small {
    width: 65px;
}
    .resume-widget .resume-item.resume-item-small .resume-item-content {
        text-align: center;
        width: 48px;
    }
    .resume-widget .resume-item.resume-item-small .resume-item-content h4 {
        font-size: var(--font-size-normal);
        font-weight: bold;
    }
.resume-widget .resume-item.resume-item-medium {
    width: 147px;
}
    .resume-widget .resume-item.resume-item-medium .resume-item-content {
        width: 90px;
    }
    .resume-widget .resume-item.resume-item-medium .resume-item-icon {
        margin-right: 5px;
    }
.resume-widget .resume-item.resume-item-odd {
    background: #212436;
    box-shadow: 0 2px 8px 0 rgba(0,0,0,0.40);
}
.resume-widget .resume-item.resume-item-large {
    width: 100%;
    max-width: 305px;
}
    .resume-widget .resume-item.resume-item-large .resume-item-content {
        width: 225px;
        padding: 15px 0px;
    }
        .resume-widget .resume-item.resume-item-large .resume-item-content h4 {
            display: inline-block;
            font-size: var(--font-size-large);
            margin-top: 0px;
            padding: 0px 24px;
        }
        .resume-widget .resume-item.resume-item-large .resume-item-content p {
            padding: 0px 10px;
            padding-top: 2px;
            width: 70px;
            float: left;
            font-size: var(--font-size-smallest-super);            
            color: #fff;
        }
    .resume-widget .resume-item.resume-item-large .resume-item-icon {
        float: left;
        width: 55px;
    }
        .resume-widget .resume-item.resume-item-large .resume-item-icon img {
            padding-top: 0px;
        }
.resume-item-last {
    margin-bottom: 0px !important;
}

.resume-graph {
    display: inline-block;
    float: left;    
    overflow: hidden;    
    background: #292C42;
    box-shadow: 0 2px 8px 0 rgba(0,0,0,0.40);
    margin: 10px 5px;
    margin-top: 0px;
    width: 100%;
    padding: 5px 10px;
    height: 130px;
}
.resume-graph.resume-graph-odd {
    background: #212436;
    box-shadow: 0 2px 8px 0 rgba(0,0,0,0.40);
}

/* FANCY BOX */
.fancybox-content {
    background: transparent !important;
    padding: 15px 15px;
    padding-top: 11px;
}

/* MAPBOX */
.mapboxgl-popup-content {
    font-size: var(--font-size-small);
    color: #000;
}
.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
    margin-bottom: 10px;
}
a.mapboxgl-ctrl-logo {
    display: none;
}















@media only screen and (min-width: 2560px)  { 
    
}
